import React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Newsletter from '../components/footer/newsletter'
import Slider from 'react-slick'
import '../components/slick/slick.scss';
import '../components/slick/slick-theme.scss';
import Modal from 'react-modal'

export default ({ data }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(data.allWordpressWpBoardsMembers.edges.map((n, z) => { return false }))

  const text_truncate = (str, length = 375, ending = '...') => {
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }

  function openModal(id) {
    setModalIsOpen(prevItems => {
      let prev = [...prevItems]
      prev[id] = true
      return prev
    })
  }

  function closeModal() {
    setModalIsOpen(prevItems => {
      let prev = prevItems.map(() => false)
      return prev
    })
  }

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    centerMode: true,
    // centerPadding: 0,
    variableWidth: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          // variableWidth: false,
        }
      }
    ],
  };

  const modalStyles = {
    content: {
      top: '58%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 110,
      borderRadius: '8px',
      width: '90%',
      height: '75%',
    }
  }

  Modal.setAppElement('#___gatsby')

  return (
    <Layout>
      <Hero mode="interactive2024" title="Title" content="Lorem ipsum dolor sit amet"  button={{title:'',url:'',target:''}}/>
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta}/>
        <div className="container__home">
          
          <div className="contentEditorBlock" dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}/>

          <div className="container__boxes" id="giveGetBoxes">
            <Link
              className="giveBox"
              to={data.wordpressPage.acf.homepage_boxes.box_top_url.url}
            >
              <div className="center">{data.wordpressPage.acf.homepage_boxes.box_top_text}</div>
            </Link>
            <Link className="imageBlend" to={data.wordpressPage.acf.homepage_boxes.box_1_url}>
              <BackgroundImage
                Tag="div"
                className="box"
                fluid={data.wordpressPage.acf.homepage_boxes.box_1_image.localFile.childImageSharp.fluid}
              >
                <div className="center" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.homepage_boxes.box_1_text}} />
              </BackgroundImage>
            </Link>
            <Link className="imageBlend" to={data.wordpressPage.acf.homepage_boxes.box_2_url}>
              <BackgroundImage
                Tag="div"
                className="box"
                fluid={data.wordpressPage.acf.homepage_boxes.box_2_image.localFile.childImageSharp.fluid}
              >
                <div className="center" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.homepage_boxes.box_2_text}} />
              </BackgroundImage>
            </Link>
            <Link target="_blank" className="imageBlend" to={data.wordpressPage.acf.homepage_boxes.box_3_url}>
              <BackgroundImage
                Tag="div"
                className="box"
                fluid={data.wordpressPage.acf.homepage_boxes.box_3_image.localFile.childImageSharp.fluid}
              >
                <div className="center" dangerouslySetInnerHTML={{__html: data.wordpressPage.acf.homepage_boxes.box_3_text}} />
              </BackgroundImage>
            </Link>
          </div>
        </div>

        <div className="boardSlider" style={{marginBottom: '150px'}}>
          <h2 className="title" style={{textAlign: 'center', color: '#8ac640', marginBottom: '100px'}}>Meet Our Board</h2>
          <Slider {...settings}>
            {
              data.allWordpressWpBoardsMembers.edges.map((n, z) => {
                return (
                  <div>
                    <BackgroundImage tag="div" className="slickSlideBG" fluid={!!n.node.featured_media && n.node.featured_media.localFile.childImageSharp.fluid}>
                      <div className="slickSlideOverlay">
                        <h4>{n.node.title}</h4>
                        <h5>{n.node.acf.board_member_title}</h5>
                        <div className="bio" dangerouslySetInnerHTML={{ __html: text_truncate(n.node.content) }} />
                        <button onClick={() => {openModal(z)}} className="button" style={{display: 'block', marginLeft: 'auto'}}>Read more</button>
                      </div>
                    </BackgroundImage>
                  </div>
                )
              })
            }
          </Slider>

          {data.allWordpressWpBoardsMembers.edges.map((n, i) => {
            return (
              <Modal
                isOpen={modalIsOpen[i]}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Board Member Modal"
              >
                <div className="modalBioWrapper" >
                  <div className="modalBioImg">
                    <Img fluid={!!n.node.featured_media && n.node.featured_media.localFile.childImageSharp.fluid} />
                  </div>
                  <div className="modalBio">
                    <h4>{n.node.title}</h4>
                    <h5>{n.node.acf.board_member_title}</h5>
                    <div className="bio" dangerouslySetInnerHTML={{ __html: n.node.content }} />
                    <button onClick={closeModal} className="button">Close</button>
                  </div>
                </div>
              </Modal>
            )
          })}

        </div>

        <Newsletter />
    </Layout>
  )
}
export const pageQuery = graphql`
query MyQuery {
  wordpressPage(wordpress_id: {eq: 10}) {
    id
    title
    acf {
      intro_text
      intro
      homepage_boxes {
        box_1_image {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 732) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        box_2_image {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 732) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        box_3_image {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 732) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        box_top_url {
          url
          target
          title
        }
        box_top_text
        box_1_url
        box_2_url
        box_3_url
        box_1_text
        box_2_text
        box_3_text
      }
    }
    wordpress_id
    guid
    slug
    content
    yoast_meta {
      name
      property
    }
    yoast_title
  }
  allWordpressWpBoardsMembers {
    edges {
      node {
        featured_media {
          source_url
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        acf {
          board_member_title
        }
        title
        content
      }
    }
  }
}
`